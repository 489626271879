<template>
  <div class="dialog-container convenienceDialog">
    <el-dialog
      :title="`${isEdit ? '修改场馆账号' : '新增场馆账号'}`"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      top="10px"
      center
      :close-on-click-modal="false"
      width="624px"
    >
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="场馆名称:" prop="venueId">
              <el-select v-model="form.venueId" placeholder="请选择" style="width:100%">
                <el-option v-for="item in venueList" :key="item.id" :label="item.venueName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="账号:" prop="venueAccount">
              <el-input v-model="form.venueAccount" maxlength="30" placeholder="数字与英文均可，4-10个字符" @blur="onBlur"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="密码:" prop="password">
              <el-input v-model="form.password" maxlength="30" placeholder="数字+英文 6-10个字符" :type="pwdDisabled ? 'password' : ''" :disabled="pwdDisabled"></el-input>
            </el-form-item>
          </el-col>
          <!-- 目前只有场馆管理员 -->
          <!-- <el-col :span="24">
            <el-form-item label="角色" prop="entityTypeId">
              <el-select v-model="form.entityTypeId" style="width:100%">
                <el-option v-for="item in mainTypeBox" :key="item.id" :label="item.typeName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="状态:" prop="onOffStatus">
              <el-select v-model="form.onOffStatus" placeholder="请选择" style="width:100%" :disabled="pwdDisabled">
                <el-option v-for="item in onOffStatusBox" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";

export default {
  mixins: [mixin],
  components: {
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    venueList: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      apis,
      onOffStatusBox: [{
        label: '开启',
        value: '0',
      }, {
        label: '禁用',
        value: '1',
      }],
      venueNameData: [],
      pwdDisabled: false,
    };
  },
  methods: {
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init()
      this.$emit('closeDialog', false)
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.pwdDisabled = false
      this.form = new AddDialogClass("form");
    },
    /* eslint-disable */
    //账号失焦查询密码
    onBlur() {
      this.$http.get(`${apis.getUserByVenueAccount}?venueAccount=${this.form.venueAccount}`).then((res) => {
        if (res.data.code === 0) {
          this.pwdDisabled = res.data.flag
          if (res.data.flag) {
            this.form.password = '******'
          } else {
            this.form.password = ''
          }
        }
      });
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          const formData = { ...this.form };
          //目前没有修改
          this.$http.post(this.isEdit ? apis.addVenueAdmin : apis.addVenueAdmin, formData).then((res) => {
            if (res.data.code === 0) {
              this.closeDialog()
            }
          })
        }
      })
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.$http.get(`${apis.findMerchantAccountInfoDTOById}?id=${data.id}`).then((res) => {
        if (res.data.code === 0) {
          this.form = {
            ...res.data.rows,
            ...{
              mainBindingEntity: res.data.rows.entityIdList,
            },
          };
        }
      });
    },
    openDialog() {
      console.log("+++++++openDialog")
    },
  },
};
</script>
<style lang="less" scoped>
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color:rgba(168,168,168,1);
  font-size:12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
}
.image-wrap{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
.line {
  width: 100%;
  height: 0;
  border: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
}
.status-col {
  /deep/.el-form-item__content {
    line-height: 20px !important;
  }
  .status-wrapper {
    width: 100%;
    .status-item {
      float: left;
      width: 50%;
      margin-bottom: 10px;
      // border: 1px solid black;
      box-sizing: border-box;
      .status-title {
        height: 30px;
        line-height: 30px;
      }
      .el-checkbox-group {
        width: 100%;
        label {
          width: 100%;
        }
      }
    }
  }
}
</style>
